a {
  text-decoration: none;
  color: blue;
}

h1, h2 {
  text-align: center;
}

button {
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  background-color: gray;
  font-weight: bold;
  padding: 3px;
  cursor: pointer;
}

.input-row {
  display: flex;
  margin: 0px 5px 15px 5px;

  .label {
    flex: 0.1;
    margin: auto;
  }

  .input {
    flex: 0.9;
    margin: auto;

    input {
      width: 100%;
      // fixes input being bigger than parent div
      box-sizing: border-box;
    }

    .error {
      outline: 2px solid red;
    }
  }
}

.err-msg {
  color: red;
}

.link-div {
  width: 90%;
  border: 2px solid gray;
  border-radius: 10px;
  background-color: #eee;
  padding: 5px;
  margin: auto;
  text-align: center;
  overflow-wrap: break-word;
}


.initial-buttons {
  button {
    display: block;
    margin: 15px auto;
    width: 200px;
    text-align: center;
  }
}

.create-link-root {
  margin-top: 20px;
}
